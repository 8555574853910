$primary: #286CA5;
$secondary: #d4e6e7;
$success: #1be708;
$warning: #ffcc00;
$danger: #cd3d64;
$dark: #08424e;
$primary-light: #00A3E8;
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #08424e !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "dark": $dark,
  "success": $success,
  "warning": $warning,
);

$card-border-color: $gray-200;
$font-size-base: 0.8125rem !default;
