.card[role="billing-card"] {
  padding: 30px 15px;
}

.billing-logo {
  text-align: center;
}
.billing-logo img {
  width: 70px;
}
.price {
  margin: 0;
  font-size: 55px;
  font-weight: 900;
  line-height: 46px;
  text-align: center;
}
.price span {
  padding: 0 5px;
  font-size: 16px;
  font-weight: 400;
}
.checkout-wrapper {
  height: 100vh;
  display: grid;
  place-items: center;
}
.checkout-wrapper .card {
  min-width: 560px;
}

.payment-success {
  display: grid;
  height: 100vh;
  place-items: center;
}
