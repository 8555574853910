#notfound {
  position: relative;
  height: 100vh;
}
#notfound.se {
  position: relative;
  height: 100%;
}
#notfound {
  position: relative;
  height: 100vh;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#notfound.se .notfound {
  position: absolute;
  left: 50%;
  top: 20%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  /* background-image: url(../img/emoji.png); */
  background-size: cover;
}

.notfound .notfound-404:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #f2f5f8;
  z-index: -1;
}
.error-found {
  position: relative;
  margin-top: 25px;
}
.triangle {
  position: relative;
}

.notfound h1 {
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}
.error-found h1 {
  font-size: 24px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}
.notfound h2,
.error-found h2 {
  font-family: "Nunito", sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;

  color: #151723;
}
.error-found h4 {
  font-size: 14px;
  font-weight: 400;
  margin: 0;

  color: #6b6b6b;
}
.notfound p {
  color: #999fa5;
  font-weight: 400;
}
.error-found p {
  color: #999fa5;
  font-weight: 400;
  margin-top: 15px;
}
.notfound a,
.error-found a {
  font-family: "Nunito", sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}
#server-error {
  position: relative;
  height: calc(100vh - 4rem);

  display: grid;
  place-items: center;
}
.triangle {
  /* width: 0;
	height: 0;
	margin: auto;
	border: 110px solid transparent;
	border-top-color: transparent;
	border-top-style: solid;
	border-top-width: 110px;
	border-bottom-color: transparent;
	border-bottom-style: solid;
	border-bottom-width: 110px;
	border-top: 0;
	border-bottom: 200px solid #f2f5f8;
	border-radius: 2px; */
}
