@import "../globals/bootstrap-custom-variables.scss";
@import "bootstrap/scss/_functions";


.page-container {
  padding-top: 4rem;
}

.header {
  nav {
    height: 4rem;
    .nav-link {
      padding: 1.5em 1em;
      background-color: transparent;
      color: #fff;

      &:hover > svg {
        color: #eee;
      }
    }
  }

  &__start {
    .nav-link {
      &__title {
        display: flex;
        align-items: center;
        color: #fff;
      }
    }
  }

  &__middle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }

  &__end {
    display: flex;
    justify-content: flex-end;

    &__nav-list {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      &__nav-item:first-child {
        position: relative;
      }
    }

    .bell-btn {
      background: transparent;
      border: none;

      &__badge {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 7px !important;
        right: 0;
        font-weight: 400;
        font-size: 0.625rem;
        width: 20px;
        height: 20px;
        padding: 11px;
      }
    }
  }

  .dropdown {
    .dropdown-menu {
      background: $primary-light;
      font-size: inherit;
      position: absolute;
      margin: 0;

      .dropdown-link {
        display: flex;
        align-items: center;
        gap: 0.4em;
        padding: 0.5em 1em;

        &:hover {
          color: #000;
        }
      }
    }
  }
}
.breadcrumb {
  .page-title {
    // font-family: "Roboto Condensed", sans-serif;
  }
}

/*============layout=============*/
.card[role="button"] {
  border-style: dashed;

  &:hover {
    background-color: $gray-100;
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-43px, 45px);
  }
}

.card[role="dashboard"] {
  height: 100%;
}

.card[role="no-selected-card"] {
  border: 0;
  .content {
    text-align: center;
    display: grid;
    place-items: center;
    height: calc(100vh - 400px);
  }
}

div[role="mobileList"] {
  padding: 15px 0px;
}

div[role="mobileList"] {
  a {
    text-decoration: none;
  }
}
div[role="project"] {
  // height: 270px;
  &:hover {
    border: 1px solid rgb($primary, 0.4);
  }
  .card-title {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    .project-name {
      max-width: 155px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
}

div[role="project"] .dropdown .dropdown-menu {
  min-width: 8rem;
}

.notifiaction-list {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    display: none;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    display: block;
  }
}
.notifiaction-list.dropdown-menu {
  transform: translateY(20px);
  transition: all 0.2s ease-in;
  display: block !important;
  visibility: hidden;
  opacity: 0;
}
.notifiaction-list.dropdown-menu.show {
  transform: translateY(-2px) !important;
  visibility: visible;
  opacity: 1;
}
.notifiaction-list.dropdown-menu:after {
  position: absolute;
  right: 5%;
  top: -9px;
  width: 0;
  height: 0;
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.filter-option > li:hover {
  background-color: $gray-100;
}

.pagination-item {
  .selected {
    background-color: $primary;
    color: $secondary;
  }
}

// .card[role="dashboard"] h2 {
// 	font-family: cursive;
// }

.project-container {
  .table-wrapper {
    &__container {
      height: 66vh;
      overflow-y: scroll;
    }
  }
}

.table-wrapper {
  display: block;
  max-width: 100%;
  overflow-y: hidden;

  &__filter {
    display: flex;
    gap: 15px;
    padding-bottom: 1.5em;

    input,
    select {
      width: fit-content;
      outline: none;
      box-shadow: none;
    }
    select,
    .dropdown-toggle {
      color: #42526e;
      &:hover {
        background: #f4f5f7;
      }
    }
    .dropdown li:hover,
    a:hover {
      background: #f4f5f7;
      border-left: 1px solid $primary;
      cursor: pointer;
    }
    .dropdown.show .dropdown-toggle {
      background: #253858;
      color: #fff;
    }

    &__searchbar {
      position: relative;

      input {
        padding-left: 2.2rem;
      }
    }
  }

  &__container {
    height: 74vh;
    overflow-y: scroll;

    &__inner {
      padding: 0 10px;
      overflow: hidden;
      margin-right: 0.3em;
      border-radius: 0.3em;
      background-color: #f4f5f7;
    }
  }

  &__active {
    background: #deebff;
  }

  table {
    width: 100%;
    background: #fff;
    border-spacing: 0;

    tr {
      border-bottom: 1px solid #eee;
    }

    th {
      color: #000;
      font-weight: 500;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    th,
    td {
      margin: 0;

      cursor: pointer;
    }

    th {
      padding: 1em 1em;
    }

    td {
      padding: 0.675em 1em;
    }

    thead {
      tr {
        background-color: #f4f5f7;
      }
    }
  }
}

.swal2-popup {
  font-size: 0.875rem !important;
}

.wizard-active {
  position: relative;
  color: $primary !important;
  border-radius: 3px;
  font-weight: 500;
  transition: 0.2s;

  &::before {
    content: "";
    border-bottom: 3px solid #388090;
    position: absolute;
    border-radius: 5px;
    bottom: -2px;
    width: 100%;
    animation: 0.5s ease-in;
  }
}

.qr-scanner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
  border-top: 98px solid #000000b5;
  border-bottom: 98px solid #000000b5;
  border-left: 98px solid #000000b5;
  border-right: 98px solid #000000b5;

  .box {
    width: 100%;
    height: 100%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  .line {
    width: 100%;
    height: calc(100% - 2px);
    transform: translateY(-100%);
    animation: radar-beam 2s infinite;
    border-bottom: 3px solid #388090;
    animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
    background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #388090 211%);
    animation-delay: 1.4s;
  }
}

.filter {
  &__list {
    max-height: 180px;
    overflow-y: auto;
  }

  &__items {
    display: flex;
    align-items: center;
    padding: 5px 0;
    font-size: 13px;
    user-select: none;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    + .active {
      color: $primary;
      background: transparent;
    }

    input[type="radio"] {
      accent-color: $primary;
    }
  }
}

.drop {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgb($primary, 0.4);
  z-index: 999;
  z-index: 9;
}

.spin {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #ffffff7a;
  z-index: 999;
}

.variables {
  ul {
    li {
      padding: 15px 15px;
      border-right: 1px solid #eee;
      border-top: 1px solid #eee;
      &:last-child {
        display: flex;
        align-items: center;
      }
    }

    .variables__item {
      border-radius: 2px;
      cursor: grab;
      display: flex;
      align-items: center;
      gap: 5px;
      // font-family: "Roboto Condensed", sans-serif;
      font-weight: 600;
      font-size: 14px;
      &:hover {
        background: #388090;
        color: #fff;
      }
    }
  }
}

.graph {
  height: 100%;

  .graph-grid {
    display: grid;
    position: absolute;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 1fr;
  }

  .empty {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 9;
  }

  .error {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 9;

    img {
      backdrop-filter: contrast(0.7);
    }
  }
  .graph-tooltip {
    border-radius: 3px !important;
    background: #2f3440;
    color: #fff;
    padding: 15px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
    li {
      padding: 2px 0px;
    }
  }
}

.legend {
  &__head {
    span {
      position: relative;
      padding-left: 35px;
    }

    span:first-child {
      &::before {
        content: "";
        position: absolute;
        width: 28px;
        height: 3px;
        top: 8px;
        left: 0;
        border-radius: 2px;
        background: $primary;
      }
    }

    span:last-child {
      &::before {
        content: "";
        position: absolute;
        width: 29px;
        height: 3px;
        border-bottom: 4px dotted $primary;
        top: 8px;
        border-radius: 2px;
        left: 0;
      }
    }
  }

  &__body {
    span {
      display: flex;
      align-items: center;
      font-size: 12px;

      &::before {
        content: "\2022";
        margin-right: 5px;
        font-size: 1.2rem;
      }
    }
  }
}

.variable-btn:focus {
  outline: 0;
  box-shadow: none;
}

// .swal2-icon {
// 	width: 4em !important;
// 	height: 4em !important;
// }

.swal2-icon-content {
  font-size: 2.8em !important;
}

.modal,
.filter,
.dropdown,
.variables {
  input[type="checkbox"] {
    position: relative;
    min-width: 11px !important;
    min-height: 11px !important;
    width: 11px !important;
    height: 11px !important;
    margin: 5px;
    padding-top: 0;
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: 1px solid #388090;
    box-shadow: none;
    font-size: 20px;
    text-align: center;
    line-height: 0.975em;
    border-radius: 1px;
  }

  input[type="checkbox"]:checked {
    background: #388090;
  }

  input[type="checkbox"]:checked:after {
    content: "✓";
    color: white;
    position: absolute;
    left: 3px;
    top: -3px;
    font-size: 7px;
    font-weight: 700;
  }
}

input:hover,
input:focus,
input:active,
button:hover,
button:focus,
button:active {
  outline: none;
  box-shadow: none !important;
  -webkit-appearance: none;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.date-picker {
  position: absolute;
  top: 150%;
  z-index: 9;
  right: 0;
  box-shadow: 0 0 10px rgba(154, 160, 185, 0.05);
  border-radius: 4px;
}

.chart {
  // background: #F1F1F1;
  padding: 25px 0;
  border-radius: 3px;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.chart-title table td,
th {
  padding: 1px 10px;
  font-weight: 400;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: 12px !important;
}

.filter-search {
  padding: 0.375rem 0.75rem !important;
}

//resizable
.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.drop-left {
  .dropdown-toggle {
    padding-left: 0px !important;
  }
}

/* Base styles for the element that has a title */

// filter

/* Base styles for the entire title */

/* Move directional arrows down a bit for left/right titles */
.title-left:before,
.title-right:before {
  top: 2px;
}

/* Vertically center title content for left/right titles */
.title-left:after,
.title-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}

.leaflet-right .leaflet-control {
  margin-right: 20px;
  margin-bottom: 20px;
}

.ds {
  &__date-filter {
    li {
      border-radius: 3px;
      font-weight: 600;
      // font-family: "Roboto Condensed", sans-serif;
      &:hover {
        background: #f4f5f7;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.ds-filter-title {
  // font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  font-size: 13px;
  text-align: uppercase;
}
.map-view {
  &__list {
    &__item {
      position: relative;
      padding: 2px 8px;
      font-size: 10px;
      background: #f4f5f7;
      border-radius: 30%;
      // &::before{
      // 	content: "\2022";
      // 	position: absolute;
      // 	left: 0;

      // }
    }
  }
}

.projectMapViewList {
  .accordion-button::after {
    display: none;
  }

  .accordion-button.collapsed {
    .expand-caret {
      transform: rotate(0deg);
    }
    .tree-count {
      display: block;
    }
  }
  .accordion-button {
    .expand-caret {
      transform: rotate(90deg);
    }
    .tree-count {
      display: none;
    }
  }
  .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: #000;
  }
}

.parent-tree {
  position: relative;
}
// .parent-tree::before{
// 	content: "";
// 	width: 7px;
// 	height: 7px;
// 	background-color: #676767;;
// 	border-radius: 50%;
// 	left: -14px;
// 	position: absolute;
// }
.breadcrumb .projectWeather {
  .projectStatus {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 3px 8px;
  }
}

.notificationBody {
  min-width: 300px;
}
.notificationMenu {
  min-width: 350px;
  max-height: 50vh;
  overflow-y: scroll;
}

//carousel
.carousel-indicators {
  display: none !important;
}
.sensor-plot-modal .carousel-control-prev {
  top: 240px !important;
  width: 30px;
  height: 60px;
  background: rgb(228, 228, 228);
  display: flex;
  border-radius: 0 5px 5px 0;
}
.sensor-plot-modal .carousel-control-next {
  top: 240px !important;
  width: 30px;
  height: 60px;
  background: rgb(228, 228, 228);
  display: flex;
  border-radius: 5px 0px 0px 5px;
}
.carousel-control-prev {
  top: 130px !important;
  left: -15px !important;
  display: block;
  width: 30px;
  height: 30px;
}
.carousel-control-next {
  top: 130px !important;
  display: block;
  right: -15px !important;
  width: 30px;
  height: 30px;
}
.carousel-control-prev-icon {
  width: 15px !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  width: 15px !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.sensorDescription .carousel-control-prev {
  position: absolute;
  left: -20px;
  z-index: 0;
}
.sensorDescription .carousel-control-next {
  position: absolute;
  right: -20px;
  z-index: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  padding-left: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  padding-left: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  padding-left: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.popup-success {
  background: rgb(222, 240, 216) none repeat scroll 0% 0%;
  border-radius: 2px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s ease-in;
  margin-bottom: 10px;
}

//payment

//setting
.setting-page {
  h6 {
    color: #000;
    text-transform: uppercase;
  }
  ul {
    background: #f5f7f8;
    padding: 10px;
  }
  li {
    padding: 15px 10px;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    &:hover {
      background: #dee4e6;
    }
  }
  li.active {
    background: #dee4e6;
  }
}

.lng-box {
  width: 80px;
  height: 80px;
  text-align: center;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  &:hover {
    background: #f5f7f8;
  }
  .flag {
    font-size: 35px;
  }
}
.lng-box.active {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.lng-spinner {
  position: absolute;
  text-align: center;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f8ffcc;
}

//form
.form-container {
  min-height: 6em;
  background: #eee;
  padding: 10px 10px 20px;
}

.form-check-input {
  font-size: 20px;
}

.form-error {
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.dashoard-healthStack select {
  background: transparent;
  border: 0;
}

@media (max-width: 768px) {
  .responsive-list {
    overflow-y: scroll;
  }
  .responsive-list li {
    width: 700px;
  }
}

.clickable {
  cursor: pointer;
}
.clickable.active {
  background: #f8f9fa;
}

.projectMapViewList {
  height: 73vh;
  overflow-y: scroll;
}
.projectMapViewList > li:hover {
  background: #f8f9fa;
}

@media (max-width: 768px) {
  .projectMapViewList {
    height: auto;
  }
  .responsive-list {
    overflow-y: scroll;
  }
  .responsive-list li {
    width: 700px;
  }
  .sensor-plot-modal {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 88vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .sensor-plot-modal .modal-dialog {
    margin-left: 0px;
    margin-right: 0px;
    width: 100% !important;
  }
  .sensor-plot-modal .modal-content {
    width: 100% !important;
  }
}

svg.recharts-surface tspan {
  /* font-size: 0.8rem !important; */
  color: #888 !important;
  font-family: Arial;
}

.qrWrapper {
  position: absolute;
  z-index: 3;
  display: flex;
  right: 21%;
  top: 10%;
  visibility: hidden;
  opacity: 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  padding: 6px;
  overflow: hidden;
  background: #fff;
}
.qrWrapper.show {
  visibility: visible !important;
  opacity: 1 !important;
}
#message-box.sticky-top {
  border-bottom: 1px solid #ccc;
}

@media (max-width: 992px) {
  .navbar-nav .dropdown-menu {
    position: absolute;
    top: 65px;
    left: 0px;
    z-index: 99;
    width: 100%;
  }

  .qr-scanner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 3;
    border-top: 15vh solid #000000b5 !important;
    border-bottom: 45vh solid #000000b5 !important ;
    border-left: 60px solid #000000b5 !important;
    border-right: 60px solid #000000b5 !important;
  }
  .Qrviewcontainer {
    height: 100vh;
  }
  .modal-dialog {
    margin: 0px;
  }
  .Qrviewcontainer > section {
    height: 100%;
  }
  .qrText {
    bottom: 300px !important;
  }
}

.breadcrumb {
  margin: 1.5em 0px;
}

.Qrviewcontainer {
  position: relative;
  z-index: 1;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: "";
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;
  border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  border-top-color: #388090;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: #388090;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: #388090;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: #388090;
}

@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.gButton {
  z-index: 99;
  border-radius: 50px;
  text-align: center;
  padding: 5px 34px;
  font-size: 12px;
}
.qrText {
  position: absolute;
  text-align: center;
  bottom: 50px;
  z-index: 10;
  width: 100%;
}

.navbar .menubar .dropdown-toggle.nav-link {
  padding: 8px 0px !important;
}

.qr-text-overlay {
  position: absolute;
  top: 2px;  // Adjust this as necessary to position the text above the QR box
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; // Ensure this is higher than the QR reader's z-index
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
}

.qr-reader-container {
  width: 100%;
  position: relative;
  z-index: 1; // Ensure the QR scanner stays underneath the text
}