/* TreeInformation.css */
.tree-information {
  font-family: 'Arial', sans-serif;
  background: #fff;
  color: #333;
  /* max-width: 400px; */
  margin: auto;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tree-picture {
  height: 150px;
  background: #ccc; /* Placeholder color */
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.tree-health-indicator {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50%; /* Adjust the width as necessary */
  height: 20%; /* Adjust the width as necessary */
  padding: 10px;
  border-radius: 20px;
  font-size: 0.9em;
  background-color: #4CAF50; /* Example green color */
  color: white;
}

.header {
  font-size: 1.1em;
  font-weight: bold;
  margin: 10px 0;
}

.tree-name {
  font-size: 1.8em;
  font-weight: bold;
  margin: 10px 0;
}

.latin-name {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #666;
}

.tabs {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  font-weight: bold;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}

.tab.active {
  border-bottom: 3px solid #333;
  font-weight: bold;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-item .title {
  font-weight: bold;
  margin-bottom: 5px;
}

.accordion-item .content {
  font-size: 0.9em;
  padding-left: 20px;
}

.call-to-action {
  text-align: center;
  margin-top: 30px;
  padding: 20px;
  background: #BBBBBB;
  color: white;
}

.learn-more {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  color: black;
  cursor: pointer;
}

.moisture-level-container {
  /* justify-content: space-between; */
  /* display: grid; */
  /* align-items: center; */
  /* width: 118px; */
  /* line-height: 17px; */
  align-self: strech;
}


.tree-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #f7f7f7; */
  /* padding: 20px; */
  /* border-radius: 8px; */
}
.tree-info {
  align-self: strech;
  /* text-align: left; */
  /* line-height: 17px; */
  /* justify-content: flex-end; */
  /* display: flex; */
}
.tree-info-head {
  align-self: strech;
  display: flex;
  justify-content: space-between;
  /* text-align: left; */
  /* line-height: 17px; */
  /* justify-content: flex-end; */
  /* display: flex; */
}
