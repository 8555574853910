@import "./bootstrap-custom-variables.scss";
@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

html {
  overflow: hidden auto;
  scroll-behavior: smooth;
  height: 100%;
}
body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", Tahoma, Geneva, Verdana, sans-serif;
  font-size: $font-size-base;
  color: $gray-700;
  scrollbar-width: thin;
  scrollbar-color: #ccc;
}
$a: 95vw;
$b: 200px;
$c: 10px;

@media (min-width: calc(#{$a} - #{$b})){
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(#{$a} - #{$c});
  }
}


.alert-wrapper {
  top: 65px;
}

.alert-wrapper-info {
  top: 65px;
  left: 300px;
  width: 60%;
}

a {
  color: $primary;
}

.form-control {
  margin-bottom: 5px;
}

.form-label {
  margin-bottom: 0.15rem;
}

.form-control {
  height: 36px;
  border-radius: 3px;
}
.form-control,
.btn {
  border-radius: 3px;
}
.cursor-pointer {
  cursor: pointer;
}
.btn-primary,
.btn-outline-primary:hover {
  color: #fff;
}

.btn-default {
  background: #f8f9fa;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

$utilities: map-merge(
  $utilities,
  (
    "cursor": (
      property: cursor,
      values: auto default pointer wait text move help not-allowed,
    ),
  )
);

.form-control:disabled {
  background-color: transparent;
}

table tbody tr:hover {
  background: #f8f9fa;
}
.height-fixed {
  height: calc(100vh - 8.6625rem);
  overflow-y: scroll;
}

.pagination .items .items_img {
  height: 9.375rem !important;
}

.dropdown-toggle::after {
  display: none;
}

.modal-backdrop.show {
  opacity: 0.7;
}
.modal-backdrop {
  background: #000;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0) scale(0.5) !important;
  transform: translate(0, 0) scale(0.5) !important;
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0) scale(1) !important;
  transform: translate(0, 0) scale(1) !important;
}
