.projectImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 0;
}

.projectWeather {
  font-size: 0.55rem;
}
.breadcrumb .projectWeather {
  font-size: 0.55rem;
}
.leaflet-tooltip {
  min-width: 400px;
  opacity: 1 !important;
}

.projectForm .leaflet-container {
  height: 50vh;
}
.map-view .leaflet-marker-icon {
  margin-left: -22px !important;
  margin-top: -39px !important;

  transform: translate3d(536px, 204px, 0px);
  z-index: 999;
}

.leaflet-popup {
  bottom: 15px !important;
}
