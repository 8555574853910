@import "./bootstrap-custom.scss";
@import "./typography";
.map-container {
height: 800px;
}
 
.sidebar {
background-color: rgba(35, 55, 75, 0.9);
color: #fff;
padding: 6px 12px;
font-family: monospace;
z-index: 1;
position: absolute;
top: 20;
left: 20;
margin: 12px;
border-radius: 4px;
}
